export default class {

  constructor(user) {
    this.user = user

    if (this.user) {
      Object.keys(this.user).forEach((prop) => this[prop] = this.user[prop])
    }
  }


  isAdmin() {
    return this.user?.role === 1
  }
  isStaff() {
    return this.user?.role <= 2
  }

  isRecruitment() {
    return this.user?.role <= 3
  }

  isMaster() {
    return this.user?.role <= 4
  }

  isReadonly() {
    return this.user?.role <= 5
  }

  isPsychologist() {
    return this.user?.is_psychologist
  }

}
