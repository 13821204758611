<template>
  <div>
    <Toast position="top-right" group="tr" />
    <Toast position="bottom-right" group="br" />
  </div>
</template>

<script setup>

import {useToast} from 'primevue/usetoast'
import {onUnmounted} from 'vue'
import { usePage } from '@inertiajs/vue3'
import { router } from '@inertiajs/vue3'
import Toast from 'primevue/toast'

const toast = useToast()

onUnmounted(

    router.on('success', () => {

      const flash = usePage().props.flash;

      Object.getOwnPropertyNames(flash)
          .forEach(
              function (severity) {
                if (flash[severity] !== null) {
                  toast.add({
                    severity: severity,
                    detail: flash[severity],
                    life: (severity === 'error' ? null : 5000),
                    group: 'br'
                  })
                }
              }
          )
    }),

    router.on('exception', (e) => {

      console.log(e.detail)

      /*toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Ooops ... something went wrong.',
        group: 'tr',
      })*/
    })
)

</script>
