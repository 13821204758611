import { createInertiaApp, Link, usePage } from '@inertiajs/vue3'
import {computed, createApp, h, nextTick} from 'vue'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import {scrollToFirstError} from './handlers'
import * as Sentry from "@sentry/vue"
import 'primeflex/primeflex.css'
import 'primevue/resources/primevue.min.css'
import '../sass/app.scss'
import PrimeVue from 'primevue/config'
import AppLayout from './Layouts/AppLayout.vue'
import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

import { modal } from "momentum-modal"

import User from './user'


//Vue.config.devtools = true

createInertiaApp({

  resolve: name => {

    return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')).then(page => {

      page.default.layout = AppLayout

      return page
    })
  },

  setup({ el, App, props, plugin }) {

    // Create app.
    const app = createApp({
      render: () => h(App, props),
    })

    // Modal.
    app.use(modal, {
      resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))
    })

    // Plugins.
    app.use(plugin)
    app.use(PrimeVue)
    app.use(ConfirmationService)
    app.use(ToastService)

    // if (process.env.NODE_ENV === 'production') {
    //
    // }

    // Components.
    app.component('Link', Link)
    app.component('router-link', {}) // Workaround for vue router dependency: https://github.com/primefaces/primevue/issues/1231

    // Directives.
    app.directive('autofocus', {
      async mounted(el) {
        await nextTick(() => el.focus())
      }
    })

    app.directive('styleclass', StyleClass)
    app.directive('tooltip', Tooltip)

    // Global Provides.
    app.provide('user', computed(() => {
      return new User(usePage().props.auth.user)
    }))

    app.provide('modules', computed(() => {
      return usePage().props.modules
    }));

    app.provide('demo', computed(() => {
      return usePage().props.demo
    }));

    // Sentry error tracking.
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        app,
        dsn: "https://5f370c8fd5f44f9cba2c8630ba916560@sentry.smartcode.ch/2",
        logErrors: true,
        trackComponents: true,
        tracesSampleRate: 0.5,
        beforeSend(event) {
          event.user = usePage().props.auth?.user

          return event;
        },
      });
    }

    // Mount the app.
    app.mount(el)

      // Global event listener.
      .$inertia.on('error', scrollToFirstError)

    // Gloabl properties.
    app.config.globalProperties.window = window

  },

  progress: {
    // The delay after which the progress bar will
    // appear during navigation, in milliseconds.
    delay: 250,

    // The color of the progress bar.
    color: '#FF36E2',

    // Whether to include the default NProgress styles.
    includeCSS: true,

    // Whether the NProgress spinner will be shown.
    showSpinner: false,
  }
})

