<template>
  <a :href="url" v-bind="$attrs" @click.prevent="goto" class="link-button action">
    <Button :class="_buttonClass"
            class="link-button"
            :label="label"
            v-tooltip.left="tooltip"
            :icon="`pi pi-${icon}`"
            :size="props.size"
            :loading="_loading"/>
  </a>
</template>

<script setup>

import Button from 'primevue/button'
import {computed} from 'vue'
import {router} from '@inertiajs/vue3'

const props = defineProps({
  label: String,
  tooltip: {type: String, required: false},
  url: {type: String, required: true},
  icon: {type: String, required: true},
  severity: {type: String, default: ''},
  size: {type: String, default: ''},
  buttonClass: {type: String, default: 'p-button-text p-button-rounded'},
  loading: {type: Boolean, default: false}
});

const _buttonClass = computed(() => {
  return `${props.buttonClass} p-button-${props.severity}`;
})

let _loading = $ref(props.loading);

function goto() {

  _loading = true;

  router.get(props.url, {}, {
    onFinish: () => _loading = false,
  })
}

</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
</style>
