<template>
  <div class="min-h-screen flex flex-column" ref="el">

    <div class="mx-0 md:mx-5 flex flex-column flex-auto md:mt-2 md:mb-3">
      <Menubar id="navbar" class="bg-transparent border-none mb-2" ref="navbar" :class="{'hide-menu' : navEntries.length === 0}" :model="navEntries">
        <template #start>
          <img v-if="user?.id" alt="logo" src="/images/logo.svg" height="40" class="mr-4">
          <img v-else alt="logo" src="/images/logo.png" height="40" class="mr-3">
        </template>
        <template #item="{item}">
          <Link :href="item.url"
                @click="closeMenu"
                class="mt-2 inline-block mr-4 font-semibold no-underline transition-all transition-duration-400 hover:text-800"
                :class="isCurrent(item) ? 'text-800': 'text-500'"
                style="letter-spacing: .03rem">
            {{item.label}}
          </Link>
        </template>
        <template #end>

          <div v-if="user?.id" class="flex">

            <LinkButton url="/calendar"
                        :button-class="calendarButtonClass"
                        icon="pi pi-calendar"/>

            <LinkButton url="/settings"
                        :button-class="settingsButtonClass"
                        icon="pi pi-cog" />

            <Button class="ml-2 p-button-text p-button-rounded p-button-secondary"
                    @click="confirmLogout"
                    icon="pi pi-sign-out"/>
          </div>

          <div v-else>
            <!--            <LinkButton v-if="currentUrl.startsWith('/registrieren')"-->
            <!--                        url="/anmelden"-->
            <!--                        label="Anmelden"-->
            <!--                        severity="secondary"-->
            <!--                        button-class="p-button-rounded p-button-light " />-->

            <!--            <LinkButton v-if="currentUrl === '/anmelden'"-->
            <!--                        url="/registrieren"-->
            <!--                        label="Registrieren"-->
            <!--                        severity="secondary"-->
            <!--                        button-class="p-button-rounded p-button-light" />-->

          </div>
        </template>
      </Menubar>

      <div id="content">
        <slot />
      </div>
    </div>

    <Modal />
    <Flashes />
    <ConfirmDialog />

    <Dropdown
        v-if="user.id && (impersonate?.can || impersonate?.impersonating)"
        class="fixed bg-white-alpha-80"
        style="bottom: 1rem; left: 1rem"
        v-model="instance"
        :placeholder="user.email"
        :options="instances"
        :show-clear="true"
        :auto-filter-focus="true"
        :auto-option-focus="true"
        :filter="true"
        option-label="email" />
  </div>
</template>

<script setup>

import {computed, inject} from 'vue'
import Flashes from '@/Partials/Flashes.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import Menubar from 'primevue/menubar'
import {Modal} from 'momentum-modal'
import Button from 'primevue/button'
import {router, usePage} from '@inertiajs/vue3'
import LinkButton from '@Components/Buttons/LinkButton.vue'
import Dropdown from 'primevue/dropdown'

const user = inject('user');
const impersonate = computed(() => usePage().props.impersonate);

const currentUrl = computed(() => usePage().url);

const navEntries = computed(() => {

  if (!user.value?.id) {
    return [];
  }

  let entries = [
    {
      label: 'Klienten',
      key: 'client',
      url: '/clients',

    },
    {
      label: 'Adressen',
      key: 'address',
      url: '/addresses',
    },
  ]

  entries.push({
    label: 'Rechnungen',
    key: 'invoic',
    url: '/invoicing',
  })

  return entries;
})

const calendarButtonClass = computed(() => {

  let cls = 'mr-2 p-button-text p-button-rounded p-button-secondary'

  if (usePage().url.startsWith('/calendar')) {
    cls = cls + " active"
  }

  return cls
})

const settingsButtonClass = computed(() => {

  let cls = 'mr-2 p-button-text p-button-rounded p-button-secondary'

  if (usePage().url.startsWith('/settings')) {
    cls = cls + " active"
  }

  return cls
})

const navbar = $ref()

function closeMenu(event) {
  navbar.toggle(event)
}

function isCurrent(entry) {
  return usePage().url.startsWith(`/${entry.key}`) || usePage().url.startsWith(`${entry.url}`)
}

function confirmLogout() {
  if (window.confirm('Wirklich abmelden?')) {
    router.get('/abmelden')
  }
}

const instances = computed(() => usePage().props.impersonate.instances);
const instance = computed({
  get() {
    return instances.value.find((instance) => user.value?.id === instance.id);
  },
  set(instance) {
    if (instance?.id) {
      window.location.href = `/impersonate/${instance.id}`;
    } else {
      window.location.href = '/impersonate/leave';
    }
  }
});


</script>

<style lang="scss" scoped>
:deep(.fc) {

  th {
    font-weight: 500 !important;
  }

  .fc-toolbar-title {
    font-weight: normal;
    font-size: 1.5rem;
    color: var(--gray-800);
  }

  .fc-col-header-cell-cushion, .fc-daygrid-day-number {
    color: var(--gray-800) !important;
    font-weight: 400 !important;
  }

  .fc-timegrid-slot {
    height: 2.4em; // Change This to your required height
  }

  .fc-event-time {
    font-weight: 500;
  }

  .fc-timeline-slot, .fc-timeline-event {
    cursor: pointer;
  }

  .fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
    color: var(--gray-700) !important;
    font-weight: normal;
  }

  .fc-button.fc-button-primary {
    border: 1px solid #ced4da;
    background-color: transparent;
    color: darken(#ced4da, 20%);

    &.fc-button-active {
      border: 1px solid $primary;
      background-color: $primary;
      color: #fff;
    }
  }

}
</style>
